import { GetterTree } from 'vuex'
import { ActionContext } from './../interface'
import { Caxios } from './../../utils/axios'
export interface State {
    courseTotal: any;
    courseList: Array<any>;
    courseItems: Array<any>;
}

let state: State = {
    courseTotal: {},
    courseList: [],
    courseItems: []
},
    getters: GetterTree<State, any> = {
        courseTotal: (state: State) => state.courseTotal,
        courseList: (state: State) => state.courseList,
        courseItems: (state: State) => state.courseItems,
    },
    mutations = {
        setCourseTotal(state: State, courseTotal: any): void {
            state.courseTotal = courseTotal
        },
        setCourseList(state: State, courseList: Array<any>): void {
            state.courseList = courseList
        },
        setCourseItems(state: State, obj: any): void {
            let { arr, idx, type } = obj
            if (type == 0) {
                state.courseItems = arr
            } else {
                if (arr.length > 0) {
                    state.courseItems[idx] = arr[0]
                }
            }
        }
    },
    actions = {
        async setCourseTotal(context: ActionContext, obj: any) {
            let { commit } = context,
                { stdid } = obj,
                result: any = await Caxios.get({ url: `/api/${stdid}/new/classstatis?student_id=${stdid}` });
            commit('setCourseTotal', result)
        },
        async setCourseList(context: ActionContext, obj: any) {
            console.log(obj, '返回obj')
            let { commit } = context,
                { stdid, s_type } = obj
            if (s_type === 'cancle') {
                const  result: any = await Caxios.get({ url: `/api/${stdid}/new/classstatis/${s_type}` })
                commit('setCourseList', result)
            } else {
                const  result: any = await Caxios.get({ url: `/api/${stdid}/new/classstatis/${s_type}?student_id=${stdid}&s_type=${s_type}` });
                commit('setCourseList', result)
            }
        },
        // async setCourseItems(context: ActionContext, obj: any) {
        //     let { commit } = context,
        //         { id, idx } = obj,
        //         result: any = await Caxios.get({ url: `/api/questions?ids=${id}` });
        //     if (result && Array.isArray(result)) {
        //         commit('setCourseItems', { arr: result, idx: idx, type: 1 })
        //     } else {
        //         commit('setCourseItems', { arr: [], idx: idx, type: 1 })
        //     }
        // }
    },
    personalKc = {
        namespaced: true,
        state,
        getters,
        mutations,
        actions
    };

export default personalKc
