import Layout from '@/views/layout/index.vue'
let dashboardRouter = {
    path: '/dashboard',
    component: Layout,
    redirect: '/dashboard/index',
    meta: {
        title: '首页',
        // noCache: true
    },
    children: [
        {
            path: 'index',
            name: 'Dashboard',
            component: () => import('@/views/dashboard/index.vue'),
            meta: {
                title: '首页',
                // noCache: true
            }
        }
    ]
}


export default dashboardRouter