
    import { Component, Vue, Prop } from 'vue-property-decorator'

    @Component({
        name: 'Message'
    })
    export default class Message extends Vue {
        @Prop() text!: string
        @Prop() type!: string
        @Prop() isShow!: boolean
        @Prop() top!: string
    }

