import { GetterTree } from 'vuex'
import { ActionContext, qqGroupList, qqGroupItem, navItem } from './../interface'
import { qqGroupListData } from './../config_data'
import { parseData } from '@/utils/common';

export interface State {
  clientWidth: number;
  mainWidth: number;
  cityPosition: string;
  branchschools: Array<any>;
  branchSchoolId: number;
  qqGroupList: Array<qqGroupItem>;
  navList: Array<any>;
  goToTopFlag: boolean;
  commonComponentName: string;
  registrationAgreementFlag: boolean;
}
const state: State = {
  clientWidth: 0,
  mainWidth: 1200,
  cityPosition: '',
  branchschools: [],
  branchSchoolId: 0,
  qqGroupList: [],
  navList: [
    {
      text: '首页',
      url: '/dashboard/index',
      iconFlag: false,
      componentName: '',
      etext: 'HOME'
    },
    // {
    //   text: '选课中心',
    //   url: '/course/index',
    //   iconFlag: false,
    //   componentName: '',
    //   etext: 'CURRICULUM'
    // },
    // {
    //   text: '交流中心',
    //   url: 'http://bbs.kangaroo.study',
    //   iconFlag: false,
    //   componentName: '',
    //   etext: 'INTERFLOW'
    // },
    {
      text: '隐私政策',
      url: '',
      iconFlag: false,
      componentName: 'PrivacyPolicy',
      etext: 'PRIVACY POLICY'
    },
    {
      text: '关于我们',
      url: '/aboutUs/index',
      iconFlag: false,
      componentName: '',
      etext: 'ABOUT ME'
    },
    // {
    //     text: '客户端下载',
    //     url: '',
    //     iconFlag: true,
    //     componentName: '',
    //     etext: 'CLIENT'
    // },
    {
      text: '校长信箱',
      url: '',
      iconFlag: false,
      componentName: 'HeadmasterMailbox',
      etext: 'PRINCIPAL‘S MAILBOX'
    },
    {
      text: '加入我们',
      url: 'https://job.fangtian.me/',
      iconFlag: false,
      componentName: '',
      etext: 'JOIN US'
    }
  ],
  goToTopFlag: false,
  commonComponentName: '',
  registrationAgreementFlag: false
},
  getters: GetterTree<State, any> = {
    clientWidth: (state: State) => state.clientWidth,
    mainWidth: (state: State) => state.mainWidth,
    cityPosition: (state: State) => state.cityPosition,
    branchschools: (state: State) => state.branchschools,
    branchSchoolId: (state, State) => state.branchSchoolId,
    qqGroupList: (state: State) => state.qqGroupList,
    navList: (state: State) => state.navList,
    goToTopFlag: (state: State) => state.goToTopFlag,
    commonComponentName: (state: State) => state.commonComponentName,
    registrationAgreementFlag: (state: State) => state.registrationAgreementFlag
  },
  mutations = {
    setClientWidth(state: State, clientWidth: number): void {
      state.clientWidth = clientWidth
    },
    setCityPosition(state: State, cityPosition: string): void {
      state.cityPosition = cityPosition
    },
    setBranchschools(state: State, branchschools: Array<any>): void {
      state.branchschools = branchschools
    },
    setBranchSchoolId(state: State, branchSchoolId: number): void {
      state.branchSchoolId = branchSchoolId
      localStorage.setItem('fangtian_branchSchoolId', String(branchSchoolId))
    },
    setQqGroupList(state: State, cityPosition: any): void {
      let _res: Array<qqGroupItem> = []
      qqGroupListData.forEach((itm: qqGroupList, idx: number): void => {
        if (itm.city == cityPosition.city) {
          _res = itm.list
        }
      })
      state.qqGroupList = _res
    },
    setGoToTopFlag(state: State, goToTopFlag: boolean): void {
      state.goToTopFlag = goToTopFlag
    },
    setCommonComponentName(state: State, componentName: string): void {
      state.commonComponentName = componentName
    },
    setRegistrationAgreementFlag(state: State, registrationAgreementFlag: boolean): void {
      state.registrationAgreementFlag = registrationAgreementFlag
    },
  },
  actions = {
    setClientWidth(context: ActionContext, clientWidth: any): void {
      const { commit } = context
      commit('setClientWidth', clientWidth)
    },
    setCityPosition(context: ActionContext, cityPosition: string): void {
      const { commit } = context
      commit('setCityPosition', cityPosition)
      commit('setQqGroupList', cityPosition)
    },
    async setBranchschools(context: ActionContext, branchschools: Array<any>): Promise<void> {
      const { commit, dispatch } = context,
        _res: Array<any> = [];
      // 处理列表逻辑
      if (branchschools.length > 0) {
        let _provinceArr: Array<string> = []
        branchschools.map((v: any): void => {
          v.province = v.name.substr(0, 2)
          v.city = v.name.replace('安徽', '').replace('江苏', '').replace('分校', '')
          _provinceArr.push(v.province)
        })
        _provinceArr = Array.from(new Set(_provinceArr))
        _provinceArr.forEach((item: string, idx: number): void => {
          _res[idx] = {
            province: item,
            list: []
          }
          branchschools.forEach((v: any, i: number): void => {
            if (v.province == item) {
              _res[idx].list.push(v)
            }
          })
        })
      }
      commit('setBranchschools', _res)
      const selectLocal: any = localStorage.getItem('fangtian_curCityPosition')
      if (!selectLocal) {
        const person = localStorage.getItem('fangtian_personal_Data')
        let id = ''
        if (person) {
          const p = parseData(person)
          if (p) {
            id = p.branch_school_id
          }
        }
        dispatch('setCityPosition', _res[0].list[0])
        commit('setBranchSchoolId', id || _res[0].list[0].id)
      } else {
        const obj: any = JSON.parse(selectLocal)
        _res.forEach((item: any, index: number): void => {
          if (item && item.list && Array.isArray(item.list) && item.list.length > 0) {
            item.list.forEach((itm: any, idx: number): void => {
              if (itm.id == obj.id) {
                dispatch('setCityPosition', itm)
                commit('setBranchSchoolId', itm.id)
              }
            })
          }
        })
      }


    }
  },
  common = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };

export default common
