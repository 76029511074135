
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { mapMutations, mapActions } from 'vuex'
    import { loginInter } from './../../store/interface'
    import { checkPhone, checkSPhone } from './../../utils/validate'
    import { secretKey } from './../../utils/secretKey'
 
    import UseName from './../username/index.vue'
    import UsePwd from './../password/index.vue'

    import { Caxios } from './../../utils/axios'
    import { getBranchschools } from '../../utils/common'


    @Component({
        name: 'MineLogin',
        components: { UseName, UsePwd },
        methods: {
            ...mapMutations('common', ['setCommonComponentName']),
            ...mapActions('login', ['loginEvent']),
            ...mapActions('personal', ['setPersonalData'])
        }
    })
    export default class MineLogin extends Vue {
        setCommonComponentName!: any
        loginEvent!: any
        setPersonalData!: any
        private loginForm: loginInter = {
            phone: '',
            password: ''
        }
        private hasSelected = false
        private usernameMsg = ''
        private passwordMsg = ''

        // @Watch('loginForm', { immediate: true, deep: true })
        // loginFormWatvh(newVal: loginInter): void {
        //     if(newVal.phone && newVal.password) {
        //         this.loginEvent(newVal)
        //     }
        // }
        private usenameCb (obj: any): void {
            let { phone, status } = obj
            // this.loginForm.phone = phone
            if(checkSPhone(phone)) {
                this.usernameMsg = ''
              this.loginForm.phone = phone
            } else {
                this.usernameMsg = 'Incorrect mobile number format'
              this.loginForm.phone = ''
            }
            if(status == 0) {
                this.usernameMsg = ''
            }
        }
        private usepwdCb (obj: any): void {
            let { password, status } = obj
            this.loginForm.password = password
            if(password) {
                this.passwordMsg = ''
            } else {
                this.passwordMsg = 'Password cannot be empty'
            }
            if(status == 0) {
                this.passwordMsg = ''
            }
        }
        private async curLoginEvent () {
            if(!this.usernameMsg && !this.passwordMsg) {
                let _result: any = await Caxios.post({ url: '/api/official/login', data: this.loginForm }),
                    _this: any = this;
                if(_result.id) {
                    this.setCommonComponentName('')
                    _this.$message.success('Successful Login')
                    localStorage.setItem('fangtian_phone', this.loginForm.phone)
                    if(this.hasSelected) {
                        localStorage.setItem('fangtian_user', secretKey(this.loginForm.phone, 'phone', 'encryption'))
                        localStorage.setItem('fangtian_pwd', secretKey(this.loginForm.password, 'password', 'encryption'))
                    } else {
                        this.removeItems()
                    }
                    // const obj = _result
                    if(_result.father_job === "请选择") {
                        _result['father_job'] = "Please select"
                    }
                    if(_result.mother_job === "请选择") {
                        _result['mother_job'] = "Please select"
                    }
                    this.setPersonalData(_result)
                    
                    localStorage.setItem('fangtian_personal_Data', JSON.stringify(_result))
                    getBranchschools()
                    const path = this.$route.path
                    if (path.indexOf('/course/detail') >= 0) {
                        this.$router.go(-1)
                    } else {
                       this.$router.go(0)
                    }
                } else {
                    // _this.$message.warning(_result.msg)
                  _this.$message.warning('Incorrect username or password')
                }
            }
        }
        private forgetEvent (): void {
            this.setCommonComponentName('MineForget')
            this.removeItems()
        }
        private signupEvent (): void {
            this.setCommonComponentName('MineRegister')
            this.removeItems()
        }
        private removeItems (): void {
            localStorage.removeItem('fangtian_user')
            localStorage.removeItem('fangtian_pwd')
        }
        beforeMount() {
            if(localStorage.getItem('fangtian_user') && localStorage.getItem('fangtian_pwd')) {
                this.hasSelected = true
                this.loginForm.phone = secretKey(localStorage.getItem('fangtian_user'), 'phone', 'decryption')
                this.loginForm.password = secretKey(localStorage.getItem('fangtian_pwd'), 'password', 'decryption')
            } else {
                this.hasSelected = false
            }
        }
    }
