
    import { Component, Vue } from 'vue-property-decorator'
    import { checkPhone, checkSPhone } from './../../utils/validate'

    import { Caxios } from './../../utils/axios'
    import { mapMutations } from 'vuex'

    import UseName from './../username/index.vue'
    import UsePwd from './../password/index.vue'
    import SmsCode from './../msgCode/index.vue'

    @Component({
        name: 'MineForget',
        components: { UseName, UsePwd, SmsCode },
        methods: {
            ...mapMutations('common', ['setCommonComponentName']),
        }
    })
    export default class MineForget extends Vue {
        setCommonComponentName!: any
        private usernameMsg: string = ''
        private passwordMsg: string = ''
        private passwordMsg2: string = ''
        private smscodeMsg: string = ''
        private differentFlag1: boolean = false
        private differentFlag2: boolean = false
        private loginForm: any = {
            phone: '',
            password: '',
            confirmpassword: '',
            smscode: ''
        }
        private usenameCb (obj: any): void {
            let { phone, status } = obj
            // this.loginForm.phone = phone
            if(checkSPhone(phone)) {
                this.usernameMsg = ''
                this.loginForm.phone = phone
            } else {
                this.usernameMsg = 'Incorrect mobile number format'
                this.loginForm.phone = ''
            }
            if(status == 0) {
                this.usernameMsg = ''
            }
        }
        private usepwdCb (obj: any): void {
            let { password, status } = obj
            this.loginForm.password = password
            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg = 'Wrong password format'
                } else if(password && this.loginForm.confirmpassword && this.loginForm.confirmpassword != password) {
                    this.differentFlag1 = true
                    this.passwordMsg = '两次密码输入不一致'
                } else {
                    this.resetPwd()
                }
            } else {
                this.passwordMsg = 'Password cannot be empty'
            }

            

            if(status == 0) {
                this.passwordMsg = ''
            }
        }
        private usepwdCb2 (obj: any): void {
            let { password, status } = obj
            this.loginForm.confirmpassword = password

            if(password) {
                if(password.length < 8 || password.length > 12) {
                    this.passwordMsg2 = 'Wrong password format'
                } else if(password && this.loginForm.password && this.loginForm.password != password) {
                    this.differentFlag2 = true
                    this.passwordMsg2 = '两次密码输入不一致'
                } else {
                    this.resetPwd()
                }
            } else {
                this.passwordMsg2 = 'Password cannot be empty'
            }

            if(status == 0) {
                this.passwordMsg2 = ''
            }
        }
        private resetPwd (): void {
            this.differentFlag1 = false
            this.passwordMsg = ''
            this.differentFlag2 = false
            this.passwordMsg2 = ''
        }
        private msgcodeCb (obj: any): void {
            let { smscode, status } = obj
            this.loginForm.smscode = smscode

            if(smscode) {
                this.smscodeMsg = ''
            } else {
                this.smscodeMsg = 'Verification code cannot be empty'
            }
            if(status == 0) {
                this.smscodeMsg = ''
            }
        }
        private async curLoginEvent () {
            let _data: any = { ...this.loginForm },
                _this: any = this;
            if(!this.usernameMsg && !this.passwordMsg && !this.passwordMsg2 && !this.smscodeMsg) {
                let _result: any = await Caxios.post({ url: '/api/official/forgetpwd', data: _data })
                if(!_result.msg) {
                    _this.$message.success('密码修改成功')
                    this.setCommonComponentName('MineLogin')
                } else{
                    _this.$message.warning(_result.msg)
                }
            }
        }
    }
